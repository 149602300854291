import { useState, useEffect } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../assets/png/aadishesha_logo.png';

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [menuAnimating, setMenuAnimating] = useState(false);


    useEffect(() => {
        if (mobileMenuOpen) {
            setMenuAnimating(true);
        } else {
            const timer = setTimeout(() => setMenuAnimating(false), 300); // Duration of the slide-out animation
            return () => clearTimeout(timer);
        }
    }, [mobileMenuOpen]);
    return (
        <header className="bg-none">
            <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
                <div className="flex lg:flex-1">
                    <a href="#" className="-m-1.5 p-1.5">
                        <span className="sr-only">Aadisesha Ayurvedics</span>
                        <img alt="" src={Logo} className="h-28 w-auto" />
                    </a>
                </div>
                <div className="hidden lg:flex lg:flex-grow lg:justify-end lg:gap-x-12">
                    <a href="/" className="text-2xl leading-6 text-white">
                        Home
                    </a>
                    <a href="about" className="text-2xl leading-6 text-white">
                        About Us
                    </a>
                    <a href="products" className="text-2xl leading-6 text-white">
                        Products
                    </a>
                    <a href="services" className="text-2xl leading-6 text-white">
                        Services
                    </a>
                    <div className="pl-6">
                        <a href="contact" className="mt-8 px-6 py-3 bg-green-600 rounded-lg text-white text-lg font-semibold hover:bg-green-700">
                            Contact Us
                        </a>
                    </div>
                </div>



                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="h-10 w-10" />
                    </button>
                </div>
            </nav>

            {menuAnimating && (<Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Aadisesha Ayurvedics</span>
                            <img alt="" src={Logo} className="h-28 w-auto" />
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="h-10 w-10" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <a
                                    href="/"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Home
                                </a>
                                <a
                                    href="/about"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    About Us
                                </a>
                                <a
                                    href="/products"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Products
                                </a>
                                <a
                                    href="/services"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Services
                                </a>
                                <a
                                    href="/contact"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>)}
        </header>
    );
}
