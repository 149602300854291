import React from 'react';
import Home from './components/Home';
function App() {
  return (
    <div>

      <Home />

    </div>
  );
}

export default App;
