import Logo from '../assets/png/aadishesha_logo.png';
import Cocoa from '../assets/png/coco_leaf.png';
import Loc from '../assets/png/location.png';
import Email from '../assets/png/email.png';
import Phone from '../assets/png/phone.png';

export default function Footer() {
    return (
        <footer className="relative bg-black">
            <div className="absolute inset-0">
                <img
                    src={Cocoa}
                    alt="Background"
                    className="h-full w-auto object-cover opacity-100"
                    style={{ right: 0, bottom: 0 }}
                />
            </div>
            <div className="relative mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                {/* Flex container for Desktop, Grid for Mobile */}
                <div className="grid grid-cols-1 md:flex md:justify-between">
                    {/* Brand Logo */}
                    <div>
                        <div className="mb-6 md:mb-0">
                            <a href="https://aadiseshaayurvedics.com/" className="flex items-center">
                                <img src={Logo} className="h-24 me-3" alt="Aadisesha Ayurveda" />
                                <span className="self-center text-xl lg:text-3xl font-semibold whitespace-nowrap text-white">
                                    AADISESHA AYURVEDICS
                                </span>
                            </a>
                        </div>
                    </div>

                    {/* Quick Links on top for mobile, on left for desktop */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-6">
                        {/* Quick Links */}
                        <div>
                            <h2 className="mb-6 text-3xl font-semibold text-gray-900 dark:text-white">Quick Links</h2>
                            <ul className="text-gray-500 text-xl dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">Home</a>
                                </li>
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">About Us</a>
                                </li>
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">Products</a>
                                </li>
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">Services</a>
                                </li>
                            </ul>
                        </div>

                        {/* Get In Touch */}
                        <div>
                            <h2 className="mb-6 text-lg md:text-3xl lg:text-3xl font-semibold text-gray-900 dark:text-white">
                                Get In Touch
                            </h2>
                            <ul className="text-gray-500 text-xl dark:text-gray-400 font-medium">
                                <li className="mb-4 flex items-start">
                                    <img src={Loc} alt="location" className="h-8 w-8 mr-2" />
                                    <p>
                                        Door No: 13/82A, <br />
                                        Madapally P.O, <br />
                                        Changanacherry,<br />
                                        Kottayam,<br />
                                        Kerala, Pin- 686546
                                    </p>
                                </li>
                                <li className="mb-4 flex items-start">
                                    <img src={Email} alt="email" className="h-8 w-8 mr-2" />
                                    <p>aadiseshaayurvedics@gmail.com</p>
                                </li>
                                <li className="flex items-start">
                                    <img src={Phone} alt="phone" className="h-8 w-8 mr-2" />
                                    <p>+91 446713412</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
