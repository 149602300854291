import aboutImage1 from '../assets/png/img2.png';
import aboutImage2 from '../assets/png/img3.png';

export default function About() {
    return (
        <div>
            {/* About Us Section */}
            <section className="bg-[#faf4e3] py-16 px-4 sm:px-8 lg:px-20">
                <div className="container mx-auto lg:flex lg:justify-between lg:items-center">
                    {/* Text Section */}
                    <div className="lg:w-1/2 mb-8 lg:mb-0">
                        <h3 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-2">About Us</h3>
                        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-6 leading-snug">
                            Ayurvedic - Treatise Tradition & Trust
                        </h1>
                        <p className="text-gray-600 text-md sm:text-lg md:text-xl mb-6">
                            A GMP certified Ayurvedic medicine manufacturing firm located at Madappally village, approx. 7km
                            from Changanassery Town, Kottayam Dist, Kerala. We make all classical preparations and 5 patent
                            products which are developed from our traditional thaliyolas.
                        </p>
                        <p className="text-gray-600 text-md sm:text-lg md:text-xl mb-6">
                            Aadisesha originated as a kitchen pharmacy at Kandankary, a small village of Kuttanadu Thaluk,
                            Alappuzha Dist. before three decades. The ancestors of Aadisesha are Edassery Vaidyas, who were the
                            court physicians of Chempakassery Dynasty, rulers of Ambalappuzha natturajyam. Edassery vaidyas
                            were known for curing many diseases that were considered incurable and were rewarded by the king for
                            their clinical excellence.
                        </p>
                        <button className="bg-green-600 text-lg sm:text-xl md:text-2xl text-white px-8 py-3 rounded-md hover:bg-green-700">
                            About Us
                        </button>
                    </div>

                    {/* Image Section */}
                    <div className="lg:w-1/2 relative hidden lg:flex flex-col">
                        {/* Main Image */}
                        <div className="w-full mb-6 lg:absolute lg:right-0 lg:-bottom-10 lg:w-[400px]">
                            <img
                                src={aboutImage1}
                                alt="About Image"
                                className="w-full h-auto object-cover rounded-lg shadow-md"
                            />
                        </div>

                        {/* Secondary Image */}
                        <div className="w-full lg:absolute lg:-top-20 lg:right-40 lg:w-[300px]">
                            <img
                                src={aboutImage2}
                                alt="Secondary Image"
                                className="w-full h-auto object-cover rounded-lg shadow-md"
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* Our Recent Achievements Section */}
            <section className="bg-white py-28">
                <div className="container mx-auto px-4 sm:px-8 lg:px-24">
                    <div className="flex flex-col md:flex-row md:space-x-10">
                        {/* Vertical Line */}
                        <div className="h-32 w-1 bg-green-600 rounded-full"></div>

                        {/* Left Section */}
                        <div className="mb-8 md:mb-0 md:w-1/3">
                            <h2 className="text-3xl md:text-5xl text-gray-600 mb-6">
                                Our recent <br /> achievements
                            </h2>
                        </div>

                        {/* Right Section - Grid */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:w-2/3">
                            {/* Card 1: Product Purity */}
                            <div className="flex items-center justify-start border-8 border-gray-300 p-4">
                                <div className="flex text-left">
                                    <span className="text-4xl font-bold text-green-600">100%</span>
                                    <p className="text-2xl text-gray-800 px-4">Product Purity</p>
                                </div>
                            </div>

                            {/* Card 2: Happy Customers */}
                            <div className="flex items-center justify-start border-8 border-gray-300 p-4">
                                <div className="flex text-left">
                                    <span className="text-4xl font-bold text-green-600">102+</span>
                                    <p className="text-2xl text-gray-800 px-4">Happy Customers</p>
                                </div>
                            </div>

                            {/* Card 3: Years Experience */}
                            <div className="flex items-center justify-start border-8 border-gray-300 p-4">
                                <div className="flex text-left">
                                    <span className="text-4xl font-bold text-green-600">111+</span>
                                    <p className="text-2xl text-gray-800 px-4">Years Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
